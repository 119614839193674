.title {
  display: block;
  margin-bottom: 0;
  font-family: $fontMontserrat;
  font-weight: bold;

  &--pagetitle {
    margin-bottom: 12px;
    font-size: 42px;
    line-height: 44px;
  }
  &--sectiontitle {
    margin-bottom: 16px;
    font-size: 25px;
    line-height: 28px;
  }
  &--subtitle {
    margin-bottom: 8px;
    font-size: 15px;
  }
  &--contenttitle {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 22px;
  }
  @include media("mobile") {
    &--pagetitle {
      font-size: 30px;
      line-height: 35px;
    }
    &--sectiontitle {
      font-size: 23px;
      line-height: 25px;
    }
  }
}
