.iconLink {
  display: inline-block;
  position: relative;
  max-width: 100%;
  padding-left: 13px;
  color: color(text);
  white-space: normal;
  i {
    position: absolute;
    top: 0;
    left: 0;
    transition: left 0.15s ease-out, color 0.2s ease-out;
    color: color(coal);
  }
  &:hover {
    color: color(green);
    i {
      color: color(green);
      left: -6px;
    }
  }
  &--light {
    color: white;
    i {
      color: white;
    }
    &:hover {
      color: color(hover, 300);
      i {
        color: color(hover, 300);
      }
    }
  }
  &--big {
    font-size: 15px;
    i {
      top: 4px;
    }
  }
}
