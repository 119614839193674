@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Rubik:wght@300;400;600&display=swap");

$ENV: "development" !default;
$fontRubik: "Rubik", sans-serif;
$fontMontserrat: "Montserrat", sans-serif;

@mixin defineFonts($path) {
  // if you're using non-google fonts, use a mixin like this, $ENV will correspond to process.env.NODE_ENV
  // @font-face {
  //   font-family: "Corporate S";
  //   src: url('#{$path}/corporate/CorporateS-Light.otf') format("opentype");
  //   font-weight: 300;
  //   font-style: normal;
  // }
}

@if ($ENV != "development") {
  @include defineFonts("/assets/fonts");
} @else {
  @include defineFonts("/fonts");
}
