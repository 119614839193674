.hero {
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  background-size: cover;
  &-content {
    display: flex;
    height: 400px;
    align-items: center;
  }
  &-title {
    display: inline-block;
    margin-bottom: 0;
    padding: 9px 25px 7px 25px;
    background-color: #fff;
    color: color(text);
    font-family: $fontMontserrat;
    font-weight: bold;
    font-size: 42px;
    line-height: 60px;

    &--inverted {
      background-color: color(text);
      color: #fff;
    }

    &--no-background {
      background-color: transparent;
    }
  }
  &-subtitle {
    display: inline-block;
    margin: 0 0 0 48px;
    padding: 18px 25px 14px 25px;
    background-color: color(grey, 200);
    color: color(text);
    font-family: $fontMontserrat;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }
  &-box {
    align-self: flex-end;
    padding: 30px 32px 34px 32px;
    background-color: color(grey, 100);
    width: 50%;
  }
  &--hasbox {
    .hero-content {
      align-items: flex-start;
      flex-direction: column;
      &-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 0 0;
      }
    }
  }
  &--big {
    .hero-content {
      height: 500px;
    }
  }
  @include media("laptop") {
    &-content {
      height: 300px;
    }
    &-box {
      box-sizing: border-box;
      width: 100%;
      padding: 20px;
    }
    &--big {
      .hero-content {
        height: 350px;
      }
    }
  }
  @include media("tablet") {
    &-title {
      padding: 10px 18px 7px 18px;
      font-size: 38px;
      line-height: 40px;
    }
    &-subtitle {
      padding: 12px 18px 10px 18px;
      line-height: 22px;
    }
  }
  @include media("mobile") {
    &-content {
      height: 270px;
    }
    &--big {
      .hero-content {
        height: 270px;
      }
    }
    &-title {
      font-size: 35px;
      line-height: 38px;
      background-color: rgba(#fff, 0.85);
    }
    &-subtitle {
      margin-left: 0;
      background-color: rgba(color(grey, 300), 0.85);
    }
  }
}
