.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 100;
  text-align: left;
  font-family: $fontRubik;
  font-size: 15px;
  font-weight: normal;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  border-top: 0;
  transition: opacity 0.2s 0s, max-height 0s 0.2s;
  &-content {
    padding: 0;
    transition: padding 0s 0.2s;
    background-color: white;
    box-shadow: inset 0px 1px 0px color(grey, 300);
  }
  &:after {
    position: absolute;
    z-index: -1;
    content: "";
    bottom: 0;
    height: 25px;
    left: 0;
    right: 0;
  }
  @at-root .dropdown-trigger:hover .dropdown {
    max-height: 9999px;
    overflow: visible;
    opacity: 1;
    transition: opacity 0.2s 0s;
    &-content {
      padding: 30px 0 60px 0;
      transition: none;
    }
    &:after {
      box-shadow: 0px 12px 24px rgba(black, 0.08);
    }
  }
  &-title {
    display: block;
    margin-bottom: 7px;
    font-family: $fontMontserrat;
    font-size: 15px;
    font-weight: bold;
  }
  &-list {
    margin: 0;
    padding: 0 20px 0 0;
    list-style-type: none;
    li {
      display: block;
      margin: 0;
      padding: 6px 0 6px 0;
    }
    &--seperated {
      position: relative;
      &:before {
        position: absolute;
        left: -25px;
        top: 0;
        bottom: 0;
        width: 1px;
        content: "";
        background-color: color(grey, 300);
      }
    }
  }
  &-row {
    display: flex;
    flex-wrap: wrap;
  }
  &-col {
    display: flex;
    flex-direction: column;
    > ul {
      flex-grow: 1;
    }
  }

  @include media("desktop") {
    &-list {
      &--seperated {
        &:before {
          content: none;
        }
      }
    }
  }
}
