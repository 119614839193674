@import "../config/colors";

.searchitem {
  &-title {
    font-family: $fontMontserrat;
    font-weight: 700;
    text-decoration: underline;
    font-size: 16px;
    color: color(text) !important;
    &:hover {
      color: color(hover) !important;
    }
  }
  &-link {
    display: inline-block;
    margin-top: 8px;
    font-size: 14px;
    color: color(grey, 600);
    &:hover {
      color: color(grey, 400);
    }
  }
}
