.mainlink {
  padding-bottom: 7px;
  color: color(text);
  font-family: $fontMontserrat;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  border-bottom: 2px solid color(text);
  transition: border-color 0.2s, color 0.2s;
  &:hover {
    color: color(hover);
    border-color: color(hover);
  }
}
