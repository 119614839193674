@import "../mixins/media";
@import "../config/colors";
@import "../config/sizes";

.mobilemenu {
  display: none;
  overflow: auto;
  z-index: 900;
  overscroll-behavior-y: contain;

  @include media("laptop") {
    position: fixed;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    top: $headerHeightMobile;
    bottom: 0;
    right: -100%;
    width: 100%;
    background-color: white;
    transition: right 0.3s;
    &--active {
      right: 0;
    }
  }
  &-content {
    flex-basis: 100%;
    padding: 0;
  }
  &-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid color(grey, 600);
    &-item {
      font-size: 18px;
      display: block;
      border-bottom: 1px solid color(grey, 600);
      a {
        padding: 13px 20px 10px 20px;
        display: block;
        color: color(text);
      }
      .mobilemenu-title {
        display: none;
        margin: 0 20px 6px 20px;
        font-weight: 700;
        font-size: 16px;
      }
      .mobilemenu-list {
        display: none;
        margin-bottom: 15px;
        &-item {
          border-bottom: 1px solid color(grey, 300);
          font-size: 16px;
          a {
            padding: 8px 10px 6px 35px;
          }
          &:last-child {
            border-bottom: 0;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      &--expandable {
        position: relative;
        a {
          padding-right: 40px;
        }
        .mobilemenu-list-item-icon {
          position: absolute;
          z-index: -1;
          right: 20px;
          top: 15px;
          font-size: 26px;
          line-height: 24;
          i {
            display: block;
          }
          i:first-child {
            display: none;
          }
        }
        &.mobilemenu-list-item--expanded {
          .mobilemenu-list-item-icon {
            i:first-child {
              display: block;
            }
            i:last-child {
              display: none;
            }
          }
        }
      }
      &--expanded {
        > .mobilemenu-list,
        > .mobilemenu-title {
          display: block;
        }
      }
      &:hover a,
      &:hover {
        color: color(hover);
      }
    }
  }
  &-footer {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    flex-basis: 100%;
    padding: 18px 20px;
    background-color: color(green);
    &-link {
      text-decoration: underline;
      font-size: 18px;
      color: white;
      &:hover {
        color: color(hover, 300);
      }
    }
  }
}
