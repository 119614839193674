@import "../config/sizes";
@import "../mixins/media";

.footer {
  padding: 55px 0 75px 0;
  background-color: color(green);
  color: white;
  font-family: $fontRubik;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.18;
  a {
    color: white;
    &:hover {
      color: color(hover, 300);
    }
  }
  p {
    margin-bottom: 15px;
  }
  &-title {
    font-family: $fontMontserrat;
    display: block;
    margin-bottom: 18px;
    font-weight: bold;
    font-size: 13px;
  }
  &-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      padding-bottom: 10px;
    }
  }
  @include media("tablet") {
    padding: 45px 0 65px 0;
    &-list {
      margin-bottom: 25px;
    }
  }
  @include media("mobile") {
    padding: 30px 0 40px 0;
  }
}
