.socials {
  display: flex;
  &-item {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    @include hovereffect();
  }
}
