.breadcrumbs {
  position: absolute;
  z-index: 0;
  top: 24px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
  &-wrapper {
    position: relative;
  }
  &-item {
    font-family: $fontRubik;
    font-weight: normal;
    font-size: 14px;
    color: white;
    text-decoration: underline;
    &:hover {
      color: color(hover, 300);
    }
    &--current {
      text-decoration: none;
      font-weight: 600;
      pointer-events: none;
    }
  }
  &-seperator {
    margin: 0 10px;
    font-size: 13px;
    color: white;
  }
  @include media("mobile") {
    position: static;
    margin-top: 10px;
  }
}
