@import "../config/colors";

.linkbox {
  position: relative;
  display: block;
  margin-top: 24px;
  padding: 24px 17px;
  height: 200px;
  color: white;
  background-color: color(coal);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-image: url(/img/wood.png);
  transition: background-color 0.15s;
  &-wrapper {
    margin-top: -24px;
  }
  &-header {
    height: 76px;
    overflow: hidden;
  }
  &-title {
    font-family: $fontMontserrat;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.45;
  }
  &-image {
    position: absolute;
    right: 33px;
    bottom: 33px;
  }
  &:hover {
    background-color: color(green);
  }
  &--disabled {
    pointer-events: none;
    background-color: color(grey, 400);
    &:hover {
      background-color: color(grey, 400);
    }
  }
}
