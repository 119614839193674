.spacer {
  margin-bottom: 50px;
  &--large {
    margin-bottom: 65px;
  }
  &--huge {
    margin-bottom: 100px;
  }
  @include media("tablet") {
    &--huge {
      margin-bottom: 60px;
    }
  }
  @include media("mobile") {
    &--huge {
      margin-bottom: 40px;
    }
  }
}
