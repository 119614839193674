.slideshow {
  &-controls {
    margin-top: -9px;
    display: inline-flex;
    &-next,
    &-prev {
      @include hovereffect();
      margin-right: 2px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .slick-slide {
    margin: 0 12px;
    height: inherit !important;
    > div {
      height: 100%;
    }
  }
  .slick-list {
    margin: 0 -12px;
  }
  .slick-track {
    display: flex !important;
  }
  .teaser {
    height: 100%;
    &-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      &-footer {
        margin-top: auto;
      }
    }
  }
  @include media("mobile") {
    .teaser {
      &-content {
        &-footer {
          margin-top: 0;
        }
      }
    }
  }
}
