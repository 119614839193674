.ezsearch-pager {
  margin-top: 50px;
  .page {
    margin: 0 5px;
    display: inline;
    flex-direction: column;
    min-height: auto;
    box-sizing: border-box;
    font-family: Rubik, sans-serif;
    font-size: 15px;
    background-color: transparent;
    color: color(text);
    font-weight: 400;
    line-height: 1.6;
    &:nth-last-child(2) {
      margin-right: 20px;
    }
  }
  .prev,
  .next {
    @extend .button;
    @extend .button--ghost;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .prev {
    margin-right: 15px;
  }
  @include media("mobile") {
    margin-top: 25px;
  }
}

.ezsearch-result a {
  @extend .searchitem-title;
}

.searchresults {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .ezsearch-summary {
    p {
      margin-bottom: 0;
    }
  }
  .ezsearch-result-count {
    p {
      margin-bottom: 0;
    }
  }
  .ezsearch-results {
    padding-top: 30px;
    margin-top: 5px;
    border-top: 1px solid color(grey, 400);
    flex: 0 0 100%;
  }
  .ezsearch-pager {
    flex: 0 0 100%;
  }
  @include media("tablet") {
    .ezsearch-summary {
      flex: 0 0 100%;
    }
    .ezsearch-result-count {
      flex: 0 0 100%;
    }
  }
}
