.colorbox {
  background-color: color(grey, 200);
  &-content {
    padding: 100px 0;
  }
  &--light {
    background-color: color(grey, 100);
  }
  &--white {
    background-color: white;
  }
  @include media("tablet") {
    &-content {
      padding: 60px 0;
    }
  }
  @include media("mobile") {
    &-content {
      padding: 40px 0;
    }
  }
}
