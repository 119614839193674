.rte {
  h2 {
    @extend .title;
    @extend .title--pagetitle;
  }
  h3 {
    @extend .title;
    @extend .title--sectiontitle;
  }
  h4 {
    @extend .title;
    @extend .title--subtitle;
  }
  p {
    margin-bottom: 20px;
  }
  a {
    color: color(link);
    &:hover {
      color: color(hover, 600);
    }
    &.button {
      @extend .button;
      margin-bottom: 16px;
      margin-right: 5px;
    }
  }
  > a:last-child,
  > p:last-child {
    margin-bottom: 0;
  }
}
