@import "../config/sizes";
@import "../config/colors";
@import "../mixins/vertical-center-child";
@import "../mixins/media";
@import "../mixins/hovereffect";

.header {
  position: relative;
  color: color(text);
  box-shadow: 0px 3px 3px rgba(black, 0.08);
  &-background {
    position: absolute;
    left: calc(50% - 175px);
    right: 0;
    top: 0;
    height: 48px;
    background: url(/img/bar.png) no-repeat left top;
    z-index: -1;
  }
  &-content {
    height: $headerHeight;
  }
  &-logo {
    @include hovereffect();
    &-wrapper {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
  &-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 14px;
    height: 48px;
    color: white;
    a {
      color: white;
      &:hover {
        color: color(grey, 300);
      }
    }
    &-seperator {
      margin: 0 18px;
      height: 9px;
      width: 1px;
      background-color: white;
    }
    &-link {
      margin-top: 2px;
    }
    &-tool {
      margin-top: 2px;
      font-size: 16px;
      padding: 8px;
    }
  }
  &-menu {
    height: 66px;
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: right;
    font-family: $fontMontserrat;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    &-item {
      display: inline-block;
      margin: 0px 0 0 20px;
      height: 100%;
      > a,
      > span {
        display: flex;
        align-items: center;
        height: 100%;
        color: color(text);
      }
      > a {
        transition: color 0.2s;
        &:hover {
          color: color(hover);
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &--expandable > a,
      &--expandable > span {
        position: relative;
        &:before {
          position: absolute;
          content: "";
          left: -8px;
          right: -8px;
          bottom: 0;
          height: 2px;
          background-color: transparent;
          transition: background-color 0.2s;
        }
      }
      &--expandable:hover {
        > a,
        > span {
          &:before {
            background-color: color(green, 500);
          }
        }
      }
    }
  }
  &-burger {
    padding: 8px 0;
    font-size: 30px;
    color: white;
    margin-left: 10px;
    width: 43px;
    text-align: center;
    display: none;
    &-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
    }
    &:hover {
      color: color(grey, 300);
    }
    i {
      &:first-child {
        display: block;
      }
      &:last-child {
        display: none;
      }
    }
    &--active {
      i {
        &:first-child {
          display: none;
        }
        &:last-child {
          display: block;
        }
      }
    }
  }

  @include media("desktop") {
    &-menu {
      li {
        margin: 0px 0 0 8px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  @include media("laptop") {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background-color: white;
    &-content {
      height: $headerHeightMobile;
    }
    &-bar-tool {
      color: white;
      margin-top: 4px;
      font-size: 26px;
      &:hover {
        color: color(grey, 300);
      }
    }
    &-burger {
      display: inline-block;
    }
    &-background {
      height: 100%;
      background-size: auto 100%;
    }
  }
  @include media("tablet") {
    &-background {
      left: calc(50% - 120px);
    }
  }
}
