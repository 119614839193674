.paper {
  position: relative;
  padding-top: 40px;
  background-color: white;
  &-wrapper {
    margin-bottom: -100px;
  }
  &-side {
    &--left,
    &--right {
      position: absolute;
      top: 100px;
      width: 20px;
      height: 280px;
      max-height: 100%;
      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 70px;
        bottom: 0;
        background-image: linear-gradient(0deg, white, white 28%, transparent);
      }
    }
    &--left {
      right: 100%;
      box-shadow: inset -15px 0 15px -15px rgba(0, 0, 0, 0.05);
    }
    &--right {
      left: 100%;
      box-shadow: inset 15px 0 15px -15px rgba(0, 0, 0, 0.05);
    }
  }
  @include media("tablet") {
    &-side {
      &--left,
      &--right {
        display: none;
      }
    }
    &-wrapper {
      margin-bottom: 0;
    }
  }
}
