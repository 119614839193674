html {
  display: flex;
  flex-direction: column;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  font-family: $fontRubik;
  font-size: 15px;
  background-color: white;
  color: color(text);
  font-weight: normal;
  line-height: 1.6;

  &--fixedfooter {
    .page-content {
      flex-grow: 1;
      margin-bottom: 160px;
    }
  }

  @include media("laptop") {
    &--fixedfooter {
      .page-content {
        margin-bottom: 120px;
      }
    }
    &--fixedheader {
      padding-top: $headerHeightMobile;
    }
  }
}
