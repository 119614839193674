$ENV: "development";@import "../../node_modules/normalize.css/normalize.css"; // skal måske flyttes til base/page
@import "../../node_modules/slick-slider/slick/slick.scss";
@import "../../node_modules/magnific-popup/dist/magnific-popup.css";

@import "base";

@import "components/page";
@import "components/header";
@import "components/dropdown";
@import "components/footer";
@import "components/button";
@import "components/mobilemenu";
@import "components/iconlink";
@import "components/banner";
@import "components/hero";
@import "components/teaser";
@import "components/title";
@import "components/text";
@import "components/link";
@import "components/socials";
@import "components/breadcrumbs";
@import "components/rte";
@import "components/linkbox";
@import "components/colorbox";
@import "components/filter";
@import "components/slideshow";
@import "components/gallery";
@import "components/paper";
@import "components/search";
@import "components/contentbox";
@import "components/mainlink";
@import "components/searchitem";
@import "components/seperator";
@import "components/formitem";
@import "components/spacer";
@import "components/searchresults";
@import "utility/grid";
@import "utility/gap";
@import "utility/textalign";
@import "utility/visibility";
@import "utility/helper";
