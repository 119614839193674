.filter {
  display: flex;
  flex-wrap: wrap;
  &-item {
    padding-bottom: 7px;
    margin: 0 40px 18px 0;
    color: color(text);
    font-family: $fontMontserrat;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    border-bottom: 2px solid color(text);
    transition: border-color 0.2s, color 0.2s;
    &:hover {
      color: color(hover);
      border-color: color(hover);
    }
    &:last-child {
      margin-right: 0;
    }
    &--active {
      color: color(green);
      border-color: color(green);
      &:hover {
        color: color(green);
        border-color: color(green);
      }
    }
    &--disabled {
      color: color(grey, 600);
      border-color: color(grey, 600);
      &:hover {
        color: color(text);
        border-color: color(text);
      }
    }
  }
}
