$colors: (
  grey: (
    100: #fafafa,
    200: #f6f6f7,
    300: #ececec,
    400: #d8d8d8,
    500: #c2c2c2,
    600: #bdbdbd,
    700: #555555,
    800: #333333,
  ),
  coal: (
    500: #435061,
  ),
  green: (
    500: #79bc2c,
    600: #6aa527,
    700: #5c8f21,
  ),
  text: (
    500: color(grey, 800),
  ),
  hover: (
    300: color(grey, 300),
    500: color(grey, 700),
    600: color(green, 600),
  ),
  link: (
    500: color(green),
  ),
);
