@import "../config/colors";
@import "../mixins/hovereffect";

.button {
  display: inline-block;
  background-color: color(green);
  padding: 11px 23px 10px 23px;
  color: white;
  border: none;
  border-radius: 0;
  font-family: $fontMontserrat;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  transition: background-color 0.2s;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: color(green, 600);
  }
  &:active {
    color: white;
    background-color: color(green, 700);
  }
  &--disabled {
    background-color: color(grey, 400);
    color: color(grey, 600);
    pointer-events: none;
  }
  &--ghost {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px color(grey, 500);
    color: color(text);
    &:hover {
      color: color(text);
      background-color: color(grey, 300);
    }
    &:active {
      color: color(text);
      background-color: color(grey, 500);
    }
    &.button--disabled {
      background-color: transparent;
      color: color(grey, 600);
    }
  }
  &--wide {
    text-align: center;
    width: 100%;
  }
  &--mobile-wide {
    @include media("mobile") {
      text-align: center;
      width: 100%;
    }
  }
}
