@import "functions/color";
@import "config/colors";
@import "config/sizes";
@import "config/fonts";
@import "config/breakpoints";
@import "mixins/media";

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 5px 0;
}

h1 {
}

h2 {
}

h3 {
}

img {
  display: block;
  max-width: 100%;
}

hr {
  height: 1px;
  background-color: black;
  border: none;
  margin: 0;
  text-align: left;
}

table {
  border-collapse: collapse;
  width: 100%;
}

p {
  margin: 0 0 20px 0;
}

a {
  transition: color 0.2s;
  text-decoration: none;
}

small {
  font-size: 11px;
}

b {
  font-weight: bold;
}

strong {
  font-weight: bold;
}

ul {
  margin-top: 0;
  margin-bottom: 20px;
}
