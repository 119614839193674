.teaser {
  &-content {
    position: relative;
    padding-top: 24px;
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 31px;
      height: 2px;
      background-color: color(grey, 400);
    }
  }
  @include media(mobile) {
    &-content {
      padding-top: 20px;
      &:before {
        content: none;
      }
    }
  }
}
