@import "../config/grid";
@import "../config/breakpoints";
@import "../mixins/media";
@import "~@webnuts/kickstart-grid/styles/grid.scss";

@include grid-outer-container(
  (
    max-width: $gridMaxWidth,
  )
);
@include grid-outer-container(
  (
    prefix: "grid-collapsed-outer-",
    max-width: $gridMaxWidth,
  )
);

@include grid(
  (
    columns: $gridColumns,
    gutter: $gridGutter,
    breakpoints: (
      desktop: (
        gutter: $gridDesktopGutter,
        media: get-media("desktop"),
      ),
      laptop: (
        gutter: $gridLaptopGutter,
        media: get-media("laptop"),
      ),
      tablet: (
        gutter: $gridTabletGutter,
        media: get-media("tablet"),
      ),
      mobile: (
        gutter: $gridMobileGutter,
        media: get-media("mobile"),
      ),
    ),
  )
);

@include grid(
  (
    prefix: "grid-collapsed-",
    columns: 12,
    gutter: 0,
    breakpoints: (
      tablet: (
        gutter: 0,
        media: get-media("tablet"),
      ),
    ),
  )
);

.grid-collapsed-outer-container {
  padding: 0;
  max-width: $gridMaxWidth;
}

.grid-outer-container {
  padding: 0 40px;
  max-width: $gridMaxWidth + 80px;

  @include media("desktop") {
    padding: 0 25px;
  }

  @include media("tablet") {
    padding: 0 10px;
  }
}
