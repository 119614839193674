@import "../config/colors";

.formitem {
  margin-bottom: 20px;

  input {
    margin-bottom: 0;
    width: 100%;
    height: 41px;
    padding: 9px 12px 8px 12px;
    line-height: 23px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid color(grey, 400);
    font-family: $fontRubik;
    font-size: 14px;
    font-weight: normal;
    outline: 0;
    -webkit-appearance: none;
    &:focus {
      box-shadow: none !important;
    }
    &::placeholder {
      color: color(grey, 600);
    }
  }

  label {
    display: block;
    margin-bottom: 1px;
    font-family: $fontRubik;
    font-size: 14px;
  }
}
