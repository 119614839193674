.banner {
  img {
    width: 100%;
    transition: filter 0.2s;
    filter: grayscale(100%);
  }
  &:hover img {
    filter: grayscale(0%);
  }
}
