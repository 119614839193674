.search {
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;
  right: 0;
  color: color(text);
  border-top: 0;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s 0s, max-height 0s 0.2s;
  &-content {
    box-shadow: inset 0px 1px 0px color(grey, 300);
    padding: 0;
    transition: padding 0s 0.2s;
    background-color: white;
  }
  &:after {
    position: absolute;
    z-index: -1;
    content: "";
    bottom: 0;
    height: 25px;
    left: 0;
    right: 0;
  }
  &--active {
    max-height: 9999px;
    overflow: visible;
    opacity: 1;
    transition: opacity 0.2s 0s;
    .search-content {
      padding: 40px 0;
      transition: none;
    }
    &:after {
      box-shadow: 0px 12px 24px rgba(black, 0.08);
    }
  }
  &-field {
    position: relative;
    input {
      width: 100%;
      padding: 12px 60px 10px 16px;
      line-height: 40px;
      border: 1px solid color(grey, 400);
      border-radius: 4px;
      font-size: 16px;
      &::placeholder {
        color: color(grey, 600);
      }
    }
    &-icon {
      position: absolute;
      padding: 5px 10px;
      top: 50%;
      right: 13px;
      transform: translateY(calc(-50% + 1px));
      font-size: 24px;
      color: color(coal);
      &:hover {
        @include hovereffect();
      }
    }
  }
  @include media("mobile") {
    &-content {
      padding: 20px 0;
    }
  }
}
